import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";

import { subscriptionPaths } from "../../../routes/subscriptions/paths";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import { Loader, Spinner } from "../../../components/layout";
import { currencySymbol } from "../../../utils/strings";
import { decimalPrice } from "../../../utils/numbers";
import { daysToMonths } from "../../../utils/dates";
import { useAppState } from "../../../redux/state";
import { usePlanPriceDetailUtils } from "./utils";
import { Form } from "../../../components/forms";
import { URLParams } from "../../../constants";
import Styles from "./styles";



function PlanPriceDetail() {
  const { planPriceId } = useParams<URLParams>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user } = useAppState();
  const formRef = useRef<HTMLFormElement>(null);
  const {
    retrievePlanPrice, selectPlan, getSubtotal, findDiscount,
    setFormValues, formFields, discountLoading, discount,
    planPrice, activeSubscription, formValues
  } = usePlanPriceDetailUtils();


  useEffect(() => {
    if (!planPriceId) return;
    if (!user) {
      navigate(
        nonAuthPaths.SIGN_IN,
        { state: { previousPath: pathname } }
      );
    }
    retrievePlanPrice(planPriceId);
  }, [planPriceId, user])



  return (
    <Styles className="page-wrapper">
      {!planPrice ? (
        <Loader />
      ) : (
        <>
          {/* Plan Price Details */}
          <div className="plan flex column card">
            <h1 className="jumbo-title">
              Your Selected Plan:
            </h1>
            <div className="head">
              {/* Plan Name and Price */}
              <div className="name">
                {planPrice.plan.name}
              </div>
              <div className="price flex">
                {currencySymbol(planPrice.currency)}
                {decimalPrice(planPrice.amount)}
                <div className="interval">
                  {`/ ${planPrice.interval}`}
                </div>
              </div>
            </div>
            {/* Plan Description */}
            <div className="content">
              <div className="title">
                {planPrice.plan.featureTitle}
              </div>
              <ul className="features flex column">
                {planPrice.plan.planFeatures.map(feature =>
                  <li className="feature" key={feature.id}>
                    {feature.description}
                  </li>
                )}
              </ul>
            </div>

          </div>

          <div className="checkout flex column">
            {/* Sub Total */}
            <div className="subtotal card">
              <h2 className="jumbo-title">
                Subtotal
              </h2>
              <div className="content">
                {/* Subtotal Calculation */}
                {getSubtotal()}

                {/* Checkout action */}
                {(
                  activeSubscription &&
                  (activeSubscription.planPriceId === planPrice.id)
                ) ? (
                  <Link
                    className="button full-width white-black choose-plan"
                    to={subscriptionPaths.planList()}
                  >
                    You're already Subscribed
                  </Link>
                ) : (
                  <button
                    className="button full-width choose-plan"
                    type="button"
                    onClick={(e) => selectPlan(e, planPrice, planPrice.plan)}
                  >
                    Checkout
                  </button>
                )}
              </div>
            </div>

            {/* Discount */}
            <div className="discount-form">
              <h2 className="jumbo-title">
                Have a Discount Code?
              </h2>
              <Form
                fields={formFields}
                values={formValues}
                setValues={setFormValues}
                onSubmit={findDiscount}
                ref={formRef}
              >
                {discountLoading ? (
                  <div className="mlr-auto">
                    <Spinner />
                  </div>
                ) : (
                  <button
                    className="button mlr-auto white-black"
                    type="submit"
                  >
                    Find Discount
                  </button>
                )}
              </Form>
            </div>

            {discount ? (
              <div className="applied-discounts">
                <h2 className="jumbo-title">
                  Applied Discount
                </h2>
                <div className="discount">
                  <h3 className="title">
                    {discount.code}
                  </h3>
                  <div className="description flex center">
                    <h4>{discount.percentage}%</h4> off for{" "
                    }{daysToMonths(discount.daysDuration)}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}
    </Styles>
  );
}

export default PlanPriceDetail;