
import { useNavigate } from "react-router-dom";
import { MouseEvent, useState } from "react";

import { useAppModal } from "../../../../components/layout/AppModal/utils";
import useSubscriptions from "../../../../requests/subscriptions";
import { consolePaths } from "../../../../routes/_console/paths";
import {
  hasActiveSubscription
} from "../../../../requests/subscriptions/utils";
import {
  ActiveSubscription,
} from "../../../../requests/subscriptions/types";
import useAuth from "../../../../requests/auth";



export const useSubscriptionDetailUtils = () => {
  const navigate = useNavigate();
  const { setAppModal, closeModal } = useAppModal();
  const { getTime } = useAuth();
  const { getMySubscription, cancelSubscription } = useSubscriptions();
  const [loading, setLoading] = useState<boolean>(true);
  const [periodEnded, setPeriodEnded] = useState<boolean>(false);
  const [activeTrialDays, setActiveTrialDays] = useState<number>();
  const [activeSubscription, setActiveSubscription] = useState<
    ActiveSubscription | null
  >(null);


  /************************/
  /** Cancel Subscription */
  const cancelCurrentSubscription = () => {
    setLoading(true);
    closeModal();
    cancelSubscription()
      .then((resData) => {
        setAppModal({
          title: "Subscription cancelled",
          children: resData.details,
          cancelCallback: () => {
            window.location.reload()
          }
        });
      }).catch(() => null)
      .finally(() => {
        setLoading(false);
      })
  }

  /**************************************/
  /** Confirm Subscription Cancellation */
  const cancelConfirm = (e: MouseEvent) => {
    e.preventDefault();
    setAppModal({
      title: "Are you sure?",
      children: (
        <div className="flex column">
          <div className="message">
            Do you want to cancel your subscription?
          </div>
          <div className="message">
            People in your family would no longer be able to access SONA.
          </div>
        </div>
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button full-width white-primary"
            onClick={() => {
              closeModal();
            }}
          >
            No, Keep my Subscription
          </button>
          <button
            className="button full-width"
            onClick={() => {
              cancelCurrentSubscription();
            }}
          >
            Yes, Cancel Subscription
          </button>
        </div>
      )
    });
  }

  /************************/
  /** Get My Subscription */
  const retrieveSubscription = () => {
    setLoading(true);
    getMySubscription()
      .then(async (subscription) => {
        const activeSubscription = hasActiveSubscription(subscription);
        if (activeSubscription) {
          setActiveSubscription(activeSubscription);
          // Set remaining trial days
          getTime()
            .then((today) => {
              if (activeSubscription.periodEnd) {
                setPeriodEnded(today > activeSubscription.periodEnd);
              }
              if (
                activeSubscription.freeTrialEnd &&
                (activeSubscription.freeTrialEnd) > today
              ) {
                const todayDate = new Date(today);
                const freeTrialEndDate = new Date(
                  activeSubscription.freeTrialEnd
                );
                freeTrialEndDate.setHours(0, 0, 0, 0);
                todayDate.setHours(0, 0, 0, 0);
                const diffInMilliseconds = (
                  freeTrialEndDate.getTime() - todayDate.getTime()
                );
                const diffInDays = (
                  Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24))
                );
                setActiveTrialDays(diffInDays > 0 ? diffInDays : 0);
              }
            })
            .catch(() => null)
            .finally(() => {
              setLoading(false)
            })
        } else {
          navigate(consolePaths.SUBSCRIPTION_OPTIONS);
          return;
        }
      }).catch(() => {
        navigate(consolePaths.SUBSCRIPTION_OPTIONS);
      });
  }



  return {
    retrieveSubscription,
    cancelCurrentSubscription,
    cancelConfirm,
    activeSubscription,
    activeTrialDays,
    periodEnded,
    loading
  }
}