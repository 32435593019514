import { subscriptionPaths } from "./paths";
import {
  PlanPriceDetail,
  PlanList,
  SubscriptionFailed,
  SubscriptionSuccess
} from "../../pages/Subscriptions/pages";
import { RouteType } from "../types";



/************************/
/** Subscription Routes */
export const subscriptionRoutes: RouteType[] = [
  {
    path: subscriptionPaths.PLAN_LIST,
    element: <PlanList />
  },
  {
    path: subscriptionPaths.SUCCESS,
    element: <SubscriptionSuccess />
  },
  {
    path: subscriptionPaths.FAILED,
    element: <SubscriptionFailed />
  },
  {
    path: subscriptionPaths.PLAN_PRICE_DETAIL,
    element: <PlanPriceDetail />
  }
]