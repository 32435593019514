import { hasActiveSubscription } from "../subscriptions/utils";
import { ActiveSubscription } from "../subscriptions/types";
import useSubscriptions from "../subscriptions";
import { PlanType } from "../plans/types";
import { User } from "../users/types";
import { Onboarding } from "./types";



const useOnboardingUtils = () => {
  const { getMySubscription } = useSubscriptions();

  /***************************************************/
  /** Should the user be prompted to setup a family? */
  const familyOnboarding = (
    activeSubscription: ActiveSubscription,
    user: User
  ): boolean => {
    const familyPlans: PlanType[] = ["standard", "family", "familyPlus"];
    // User must be on a plan that supports multiple users
    if (familyPlans.includes(
      activeSubscription.planPrice.plan.planType
    )) {
      // ...and user must be a parent
      if (user.familyRole === "admin") {
        return true;
      }
    }

    return false;
  }

  /******************************************************/
  /** Should the user be prompted for KYC verification? */
  const kycOnboarding = (
    activeSubscription: ActiveSubscription,
    user: User
  ): boolean => {
    const kycPlans: PlanType[] = ["standard", "family", "familyPlus"];
    if (kycPlans.includes(
      activeSubscription.planPrice.plan.planType
    )) {
      if (
        user.primaryFamilyAdmin?.primaryAdminId &&
        user.primaryFamilyAdmin.primaryAdminId === user.id
      ) {
        return true;
      }
    }

    return false;
  }


  /**********************************************************/
  /** Calculate what is required in the secondary checklist */
  const getRequiredOnboarding = (
    onboarding: Onboarding,
    user: User
  ): Promise<Partial<Onboarding>> => {
    return new Promise<Partial<Onboarding>>((resolve) => {
      const partialOnboarding: Partial<Onboarding> = {
        id: onboarding.id,
        avatar: onboarding.avatar,
        heritages: onboarding.heritages
      }
      getMySubscription()
        .then((subscription) => {
          const activeSubscription = hasActiveSubscription(subscription);
          if (!activeSubscription) return;
          if (familyOnboarding(activeSubscription, user)) {
            partialOnboarding.family = onboarding.family;
          }
          if (kycOnboarding(activeSubscription, user)) {
            partialOnboarding.completedKyc = onboarding.completedKyc;
          }
        })
        .catch(() => { })
        .finally(() => {
          resolve(partialOnboarding);
        });
    });
  }


  return {
    getRequiredOnboarding
  }
}

export default useOnboardingUtils;