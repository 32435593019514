export const nonAuthRoot = "/auth";



/***************************/
/* Non-Authenticated Paths */
export const nonAuthPaths = {
  SIGN_UP: `${nonAuthRoot}/sign-up/`,
  SIGN_IN: `${nonAuthRoot}/sign-in/`,
  LOAD_TOKEN: `${nonAuthRoot}/load/`,
  FORGOT_PASSWORD: `${nonAuthRoot}/forgot-password/`,
  RESET_PASSWORD: `${nonAuthRoot}/reset-password/`,
  SET_NEW_PASSWORD: `${nonAuthRoot}/set-password/`,

  // Family Invites
  SIGN_UP_FAMILY_INVITE: `${nonAuthRoot}/sign-up/family-invite/`,
  CHECK_INVITE: `${nonAuthRoot}/family-invites/:familyInviteId/`,
  checkInvite: (inviteId: number | string) =>
    `${nonAuthRoot}/${inviteId}/`,
  ACCEPT_INVITE: `${nonAuthRoot}/family-invites/:familyInviteId/accept/`,
  acceptInvite: (inviteId: number | string) =>
    `${nonAuthRoot}/${inviteId}/family-invites/accept/`
}