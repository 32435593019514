import { NavLink } from "react-router-dom";

import { User } from "../../../requests/users/types";
import { getNavlinks } from "./utils";
import Styles from "./styles";



type Props = {
  user: User;
  open: boolean;
  setOpen: (value: boolean) => any;
}

function SideBar({
  open,
  user
}: Props) {
  return (
    <Styles id="SideBar" className={open ? "open" : ""}>
      <div className="sidebar-wrapper">
        {/* Nav Links */}
        <nav className="nav-links">
          {getNavlinks(user).map((navlink) =>
            <NavLink
              key={navlink.name}
              to={navlink.path}
              className={
                ({ isActive }) => `nav-link ${isActive ? ' active' : ""}`
              }
            >
              <div className="icon">{navlink.icon}</div>
              <div className="text">{navlink.name}</div>
            </NavLink>
          )}
        </nav>
      </div>
    </Styles>
  );
}

export default SideBar;