import { Link, useLocation, useNavigate } from "react-router-dom";
import { MouseEvent, useRef } from "react";

import menu_icon from "../../../assets/vector-images/generic/menu.svg";
import logo from "../../../assets/vector-images/brands/sona-logo.svg";
import { useHiddenOutside } from "../../../utils/components";
import { nonAuthPaths } from "../../../routes/nonAuth/paths";
import useAppActions from "../../../redux/actions/app";
import { User } from "../../../requests/users/types";
import { useAppState } from "../../../redux/state";
import { rootPaths } from "../../../routes/paths";
import useAuth from "../../../requests/auth";
import { UserAvatar } from "../../layout";
import Styles from "./styles";



type Props = {
  user: User;
}

function AuthHeader({ user }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const userPopup = useRef<HTMLDivElement>(null);
  const { logOut } = useAuth();
  const { setSidebarOpen } = useAppActions();
  const { sidebarOpen } = useAppState();
  const {
    visible: dropdown,
    setVisible: setDropdown
  } = useHiddenOutside(userPopup);


  /************************/
  /** Logout confirmation */
  const confirmLogout = async (e: MouseEvent) => {
    e.preventDefault();
    logOut()
      .catch(() => null)
      .finally(() => {
        navigate(nonAuthPaths.SIGN_IN);
      });
  }

  /************************/
  /** Toggle Sidebar Menu */
  const toggleMenu = (e: MouseEvent) => {
    e.preventDefault();
    setSidebarOpen(!sidebarOpen);
  }


  return (
    <Styles className="root-header">
      <div className="header-wrapper">
        {/* Show hamburger when in Console */}
        {pathname.startsWith("/manage") ? (
          <button
            style={{ backgroundImage: `url(${menu_icon})` }}
            onClick={toggleMenu}
            className="sidebar-toggle"
          />
        ) : null}

        {/* Main Logo */}
        <Link to={rootPaths.HOMEPAGE}>
          <img src={logo} alt="" id="logo" />
        </Link>

        {/* User Avatar */}
        <div className="avatar-wrapper" ref={userPopup}>
          <UserAvatar
            user={user}
            onClick={() => setDropdown(!dropdown)}
          />
          {dropdown ? (
            <div className="dropdown-menu">
              <button
                className="user-dropdown"
                onClick={confirmLogout}
              >
                Logout
              </button>
            </div>
          ) : null}
        </div>
      </div>
    </Styles>
  );
}

export default AuthHeader;