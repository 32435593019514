import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { useAppModal } from "../../../components/layout/AppModal/utils";
import ananse_smile from
  "../../../assets/vector-images/ananse/bust_smile_staff_no glow.svg";
import { missionPaths } from "../../../routes/missions/paths";
import quest_items from
  "../../../assets/raster-images/mission/quest-items.png";
import { OnStartModal } from "./styles";


const useOnboardingLessonUtils = () => {
  const navigate = useNavigate();
  const { setAppModal, closeModal } = useAppModal();
  const [playing, setPlaying] = useState<boolean>(false);


  /***********************/
  /** Show Intro Message */
  const onStart = () => {
    setAppModal({
      title: "Welcome to SONA!",
      children: (
        <OnStartModal className="flex column">
          <div className="img-wrapper">
            <img src={ananse_smile} alt="" />
          </div>
          <figcaption className="flex column">
            <div className="title bold">
              Hello, I'm Ananse
            </div>
            <div className="description">
              Nice to meet you.
              <br />
              Let's begin by going back to the start.
            </div>
          </figcaption>
        </OnStartModal>
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button full-width"
            onClick={() => {
              closeModal();
              setPlaying(true);
            }}
          >
            Let's Go!
          </button>
        </div>
      ),
      cancelCallback: (
        () => {
          setPlaying(true);
        }
      )
    });
  }

  /*******************************/
  /** Show End of Lesson Message */
  const onEnded = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
        .catch((err) => console.error(err));
    }
    setAppModal({
      title: "Your first mission starts now",
      children: (
        <div className="flex column">
          <img src={quest_items} alt="" />
          <div className="description">
            Let's unlock the items for your quest by {" "
            }answering some questions on this mission.
          </div>
        </div>
      ),
      controls: (
        <div className="modal-controls">
          <button
            className="button full-width white-black"
            onClick={() => {
              closeModal();
              setPlaying(true);
            }}
          >
            Watch Again
          </button>
          <button
            className="button full-width "
            onClick={() => {
              closeModal();
              navigate(missionPaths.ONBOARDING_QUIZ);
            }}
          >
            Take Quiz
          </button>
        </div>
      ),
      cancelCallback: (
        () => {
          navigate(missionPaths.ONBOARDING_QUIZ);
        }
      )
    });
  }


  return {
    onStart,
    onEnded,
    playing
  }
}

export default useOnboardingLessonUtils;