import { Link, useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";

import { getActiveDiscount } from "../../../../requests/subscriptions/utils";
import { subscriptionPaths } from "../../../../routes/subscriptions/paths";
import { consolePaths } from "../../../../routes/_console/paths";
import { currencySymbol } from "../../../../utils/strings";
import { decimalPrice } from "../../../../utils/numbers";
import { daysToMonths } from "../../../../utils/dates";
import { Loader } from "../../../../components/layout";
import { useSubscriptionDetailUtils } from "./utils";
import {
  SubscriptionDiscount
} from "../../../../requests/subscriptions/types";
import { ConsoleContext } from "../..";
import Styles from "./styles";



function SubscriptionDetail() {
  const { user } = useOutletContext<ConsoleContext>();
  const {
    retrieveSubscription, cancelConfirm, loading,
    activeSubscription, activeTrialDays, periodEnded
  } = useSubscriptionDetailUtils();
  const [activeDiscount, setActiveDiscount] = useState<
    SubscriptionDiscount | null
  >(null);

  useEffect(() => {
    retrieveSubscription();
  }, [])

  useEffect(() => {
    if (!activeSubscription) return;
    const discount = getActiveDiscount(activeSubscription);
    setActiveDiscount(discount);
  }, [activeSubscription])



  return (
    <Styles className="page-wrapper">
      {(!loading && activeSubscription) ? (
        <>
          <header>
            <div className="flex">
              <h1 className="title">
                Subscription
              </h1>
            </div>
          </header>

          {/* Active Plan */}
          <div className="subscription-plan">
            <section className="plan-summary">
              {/* Plan Name */}
              <div className="head flex align-center">
                <div className="name">
                  {activeSubscription.planPrice.plan.name} Plan
                </div>
                <span>-</span>
                <div className="price flex bold">
                  <div className="value">
                    {currencySymbol(activeSubscription.planPrice.currency)}
                    {decimalPrice(activeSubscription.planPrice.amount)}
                  </div>
                  <span>/</span>
                  <div className="interval">
                    {activeSubscription.planPrice.interval}
                  </div>
                </div>
              </div>
              {/* Plan Discount */}
              {activeDiscount ? (
                <div className="discount flex">
                  <div className="title">
                    Active Discount
                  </div>
                  <span>-</span>
                  <div className="details grey-text">
                    {(
                      activeDiscount.planPriceDiscount.percentage
                    )}% off for {""
                    }{daysToMonths(
                      activeDiscount.planPriceDiscount.daysDuration
                    )}
                  </div>
                </div>
              ) : null}
            </section>
            <div className="content">
              <div className="title">
                {activeSubscription.planPrice.plan.featureTitle}
              </div>
              <ul className="features flex column">
                {activeSubscription.planPrice.plan.planFeatures.map(
                  (feature) =>
                    <li className="feature" key={feature.id}>
                      {feature.description}
                    </li>
                )}
              </ul>
            </div>

            {user.familyRole === "admin" ? (
              <div className="flex column">
                {/* Free Trial */}
                {(
                  activeSubscription.status === "trial" &&
                  activeTrialDays
                ) ? (
                  <div className="free-trial grey-text">
                    You have {activeTrialDays} days left on your free trial.
                  </div>
                ) : null}
                <div className="subscription-status flex column">
                  {(
                    ["cancelled", "expired"].includes(
                      activeSubscription.status
                    )
                  ) ? (
                    // Subscription ended (cancelled, expired)
                    periodEnded ? (
                      <>
                        <div>
                          Your subscription expired on ${moment(
                            activeSubscription.periodEnd
                          ).format("Do MMMM, YYYY")}
                        </div>
                        <Link
                          to={consolePaths.SUBSCRIPTION_OPTIONS}
                          className="link underline mt-1"
                        >
                          Renew Plan
                        </Link>
                        <Link
                          to={consolePaths.SUBSCRIPTION_OPTIONS}
                          className="link underline mt-1"
                        >
                          Switch to a different Plan
                        </Link>
                      </>
                    ) : (
                      // Subscription about to end
                      <>
                        <div>
                          Your subscription would expire on {moment(
                            activeSubscription.periodEnd
                          ).format("Do MMMM, YYYY")}.
                        </div>
                        <div>
                          Don't let your Family lose access!
                        </div>
                        <Link
                          className="link underline mt-1"
                          to={subscriptionPaths.planPriceDetail(
                            activeSubscription.planPriceId
                          )}
                        >
                          Renew Plan
                        </Link>
                        <Link
                          to={consolePaths.SUBSCRIPTION_OPTIONS}
                          className="link underline mt-1"
                        >
                          Switch to a different Plan
                        </Link>
                        <button
                          onClick={cancelConfirm}
                          className="link underline cancel-plan"
                        >
                          Cancel Subscription
                        </button>
                      </>
                    )
                  ) : activeSubscription.status === "overdue" ? (
                    // Issue billing card (overdue)
                    <>
                      <div>
                        We could not bill your selected payment method.
                        <br />
                        Please ensure it is active and you have funds.
                      </div>
                      <Link
                        to={subscriptionPaths.planPriceDetail(
                          activeSubscription.planPriceId
                        )}
                        className="link underline mt-1"
                      >
                        Change Payment Method
                      </Link>
                      <Link
                        to={consolePaths.SUBSCRIPTION_OPTIONS}
                        className="link underline mt-1"
                      >
                        Switch to a different Plan
                      </Link>
                      <button
                        onClick={cancelConfirm}
                        className="link underline cancel-plan"
                      >
                        Cancel Subscription
                      </button>
                    </>
                  ) : ["active", "trial"].includes(activeSubscription.status) ? (
                    // Active subscrption (active)
                    <div className="flex column">
                      <Link
                        to={consolePaths.SUBSCRIPTION_OPTIONS}
                        className="link underline mt-1"
                      >
                        Switch to a different Plan
                      </Link>
                      <button
                        onClick={cancelConfirm}
                        className="link underline cancel-plan"
                      >
                        Cancel Subscription
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Styles>
  );
}

export default SubscriptionDetail;