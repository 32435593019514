import { Link } from "react-router-dom";
import { useEffect } from "react";

import { subscriptionPaths } from "../../../routes/subscriptions/paths";
import { profileSetupPaths } from "../../../routes/profileSetup/paths";
import filter from "../../../assets/vector-images/generic/filter.svg";
import { LabelTooltip, SelectField } from "../../../components/forms";
import { consolePaths } from "../../../routes/_console/paths";
import { currencySymbol } from "../../../utils/strings";
import { decimalPrice } from "../../../utils/numbers";
import { Loader } from "../../../components/layout";
import { useAppState } from "../../../redux/state";
import useSubscriptionListUtils from "./utils";
import Styles from "./styles";



function PlanList() {
  const { user } = useAppState();
  const {
    IntervalOptions, activeSubscription, loading,
    interval, plans, intervalValue, firstRender,
    getPlans, reloadPlanList, updateInterval, setLoading
  } = useSubscriptionListUtils(user);


  /***************/
  /** List Plans */
  const getPlanList = async () => {
    if (firstRender) return;
    try {
      setLoading(true);
      await reloadPlanList()
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    getPlanList();
  }, [interval])

  useEffect(() => {
    getPlans();
  }, [])



  return (
    <Styles className="root-content">
      <div className="page-wrapper">
        {(loading) ? (
          <Loader />
        ) : (
          <>
            {/* Heading */}
            <header className="flex justify-between align-center wrap">
              <div className="heading">
                <h3 className="title">
                  Select Subscription Plan
                </h3>
                <div className="description">
                  Choose a subscription to continue with SONA
                </div>
              </div>
              <div className="field-column">
                <LabelTooltip htmlFor="interval">
                  Billing Cycle
                </LabelTooltip>
                <SelectField
                  name="interval"
                  icon={filter}
                  options={IntervalOptions}
                  value={intervalValue}
                  onChange={updateInterval}
                  placeholder="Billing Cycle"
                />
              </div>
            </header>

            {/* Available Plans */}
            <section className="content-wrapper">
              <div className="plans">
                {plans.map((plan) => (
                  <div className="plan flex column" key={plan.id}>
                    <div className="head">
                      {/* Plan Name and Price */}
                      <div className="name">
                        {plan.name}
                      </div>
                      <div className="price flex">
                        {currencySymbol(plan.planPrices[0].currency)}
                        {decimalPrice(plan.planPrices[0].amount)}
                        <div className="interval">
                          {`/ ${plan.planPrices[0].interval}`}
                        </div>
                      </div>
                    </div>
                    {/* Plan Description */}
                    <div className="content">
                      <div className="title">
                        {plan.featureTitle}
                      </div>
                      <ul className="features flex column">
                        {plan.planFeatures.map(feature =>
                          <li className="feature" key={feature.id}>
                            {feature.description}
                          </li>
                        )}
                      </ul>
                    </div>
                    {(
                      user &&
                      activeSubscription &&
                      (activeSubscription.planPriceId === plan.planPrices[0].id)
                    ) ? (
                      <Link
                        className="button full-width submit white-black choose-plan"
                        to={user.completedOnboarding
                          ? consolePaths.SUBSCRIPTION
                          : profileSetupPaths.NAME_ENTRY
                        }
                      >
                        Subscribed
                      </Link>
                    ) : (
                      <Link
                        className="button full-width submit choose-plan"
                        to={subscriptionPaths.planPriceDetail(
                          plan.planPrices[0].id
                        )}
                      >
                        Choose Plan
                      </Link>
                    )}
                  </div>
                ))}
              </div>
              {user ? (
                <div className="free-trial">
                  7-day free trial.
                  You can cancel or upgrade your plan anytime!
                </div>
              ) : (
                <div className="free-trial">
                  Get a 7-day free trial when you sign up.
                  You can cancel or upgrade your plan anytime!
                </div>
              )}
            </section>
          </>
        )}
      </div>
    </Styles>
  );
}

export default PlanList;