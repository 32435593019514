import axios from "axios"

import { ErrorResponse } from "../../types";
import { PlanPriceDiscount } from "./types";



/*  ##################################### */
/** ## PLAN PRICE DISCOUNT API REQUESTS # */
/*  ##################################### */
const usePlanPriceDiscounts = () => {
  /*********************************************/
  /** Get Plan Price Discount by Discount Code */
  const getPlanPriceDiscountByCode = (code: string) => {
    return new Promise<PlanPriceDiscount>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/plan-price-discounts/code/${code}`
      }).then((res: any) => {
        const resData = res.data as PlanPriceDiscount;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }


  return {
    getPlanPriceDiscountByCode
  }
}

export default usePlanPriceDiscounts;