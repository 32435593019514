import {
  PlanList,
  PlanPriceDetail
} from "../../pages/Subscriptions/pages";
import { RouteType } from "../types";
import {
  Account,
  SubscriptionDetail,
  UpgradeOptions,
  Download
} from "../../pages/_console/pages";
import {
  CreateEditUser,
  FamilyDetail,
  CreateInvite,
  ViewInvites,
  FamilySetup
} from "../../pages/Family/pages";
import {
  consoleFamilyPaths,
  consoleSubscriptionPaths,
  consolePaths
} from "./paths";



/*******************/
/** Console Routes */
export const consoleRoutes: RouteType[] = [
  {
    path: consolePaths.DASHBOARD,
    element: <Download />
  },
  {
    path: consolePaths.ACCOUNT,
    element: <Account />
  },
  {
    path: consolePaths.SUBSCRIPTION,
    element: <SubscriptionDetail />
  },
  {
    path: consolePaths.SUBSCRIPTION_OPTIONS,
    element: <UpgradeOptions />
  }
]


/****************************/
/** Console > Family Routes */
export const consoleFamilyRoutes: RouteType[] = [
  {
    path: consoleFamilyPaths.SETUP,
    element: <FamilySetup />
  },
  {
    path: consoleFamilyPaths.DETAIL,
    element: <FamilyDetail />
  },
  {
    path: consoleFamilyPaths.ADD_USER,
    element: <CreateEditUser />
  },
  {
    path: consoleFamilyPaths.EDIT_USER,
    element: <CreateEditUser />
  },
  {
    path: consoleFamilyPaths.CREATE_INVITE,
    element: <CreateInvite />
  },
  {
    path: consoleFamilyPaths.VIEW_INVITES,
    element: <ViewInvites />
  }
]


/**********************************/
/** Console > Subscription Routes */
export const consoleSubscriptionRoutes: RouteType[] = [
  {
    path: consoleSubscriptionPaths.PLAN_LIST,
    element: <PlanList />
  },
  {
    path: consoleSubscriptionPaths.PLAN_PRICE_DETAIL,
    element: <PlanPriceDetail />
  }
]